html, body {
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
// mat-toolbar, mat-toolbar mat-toolbar-row {
//   height: 50px;
// }
.mat-card {
  padding: 10px !important;
}
table {
  width: 100%;
}
select {
  width: 100%;
  height: 3em;
}
select option {
  padding: 1.5em;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.new {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.new .mat-button-wrapper {
  font-size: 2em;

}
.booked .mat-list-item, .mat-card.booked {
  background-color: #EBF9E6;
}
.cancelled .mat-list-item, .mat-card.cancelled {
  background-color: #fcf8e3;
}
.finished .mat-list-item, .mat-card.finished {
  background-color: #F5F5F5;
}
.pull-right {
  float: right;
}
.label {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
}
.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.label-default {
    background-color: #777777;
}
.label-success {
    background-color: #5cb85c;
}
.label-primary {
    background-color: #337ab7;
}
.label-danger {
    background-color: #d9534f;
}
.label-info {
    background-color: #5bc0de;
}
// hack for combase
go-loading::before, .mat-card-actions::before, go-items-detail::before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
go-items-detail {
  margin-bottom: 5px;
  display: block;
}
mat-dialog-container {
  z-index: 1001;
  position: relative;
}

pagination-template, pagination-template div{
  height: 100%;
}

.full-width {
  width: 100%;
}
